export const ORDER_STATES = {
  OPEN: 'OPEN',
  PENDING: 'PENDING',
  CLOSED: 'CLOSED',
  RESOLVED: 'RESOLVED',
  REJECTED: 'REJECTED',
};

interface ISourceModel {
  DATE: string;
  TEXT: string;
}
export const TYPES: ISourceModel = {
  DATE: 'date',
  TEXT: 'text',
};
export type OrderTypeCodeInterfaceType = 'FULFILL' | 'CUSTOMER';
export type ServiceTypeCodeType = 'MISC' | 'TYP' | null;
export const CRYO_SERVICE_CODE = 'CRYO';
export const ORDER_CODES = {
  MNC_APHERESIS: 'UW',
  CUSTOMIZED_TYPING: 'HR',
};
export const ORDER_TYPE = {
  CT: {
    type: 'CT',
    name: 'Confirmatory Typing',
    label: 'CART.CONFIRMATORY_TYPING',
    order: 1,
  },
  HE: {
    type: 'HE',
    name: 'Hold',
    label: 'CART.HOLD',
    order: 4,
  },
  CCT: {
    type: 'CCT',
    name: 'Confirmatory Typing',
    label: 'CART.CONFIRMATORY_TYPING',
    hasServices: true,
    order: 1,
  },
  DR: {
    type: 'DR',
    name: 'DR Typing',
    label: 'CART.DR_TYPING',
    //TODO: set the correct order value when it becomes known
    order: 11,
  },
  OR: {
    type: 'OR',
    name: 'Cord Shipment',
    label: 'CART.CORD_SHIPMENT',
    order: 3,
  },
  EWORKUP: {
    type: 'EWORKUP',
    name: 'HPC, Workup',
    label: 'CART.HPC_WORKUP',
    order: 4,
  },
  EWU: {
    type: 'EWU',
    name: 'HPC, Workup',
    label: 'CART.HPC_WORKUP',
    order: 4,
  },
  HW: {
    type: 'HW',
    name: 'Held For Workup',
    label: 'CART.HELD_FOR_WORKUP',
    order: 3,
  },
  [ORDER_CODES.CUSTOMIZED_TYPING]: {
    type: ORDER_CODES.CUSTOMIZED_TYPING,
    name: 'Customized Typing',
    label: 'CART.CUSTOMIZED_TYPING',
    hasServices: true,
    order: 2,
  },
  PBSC: {
    type: 'PBSC',
    name: 'HPC, Apheresis',
    label: 'CART.PBSC',
    order: 5,
  },
  WU: {
    type: 'WU',
    name: 'HPC, Marrow',
    label: 'CART.WU',
    order: 6,
  },
  [ORDER_CODES.MNC_APHERESIS]: {
    type: 'UW',
    name: 'MNC, Apheresis',
    label: 'CART.UW',
    order: 7,
  },
  WB: {
    type: 'WB',
    name: 'NC, Whole Blood',
    label: 'CART.WB',
    order: 8,
  },
  AT: {
    type: 'AT',
    name: 'Additional Tubes',
    label: 'CART.AT',
    order: 12,
  },
  SECEWU: {
    type: 'SECEWU',
    name: 'Subsequent Workup',
    label: 'CART.SECEWU',
    //TODO: currently this one should be the last one
    order: 13,
  },
};
